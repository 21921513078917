const idPortalApi = "09ceeeb66c9c9fd89f7e2e9c28830b847dd37645";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  // urlApi: `http://localhost:8004/${idPortalApi}`,
  urlADM: `https://administracaopublica.com.br`,

  //config botões e imagens
  dnsPortal: "cmriachao.ma.gov.br",
  banner_defalt: true, //se não quiser o banner transparencia azul padrao

  NomePortal: "Riachão",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 4, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]

  urlEmDeploy: "https://cmriachao.ma.gov.br", //usada para link de compartilhamento
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",
  link_mapa: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d884243.9971249776!2d-46.69342845!3d-7.7472214500000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92d65b2dfe594fbb%3A0x43ce8218ebe29cad!2sRiach%C3%A3o%20-%20MA!5e1!3m2!1spt-BR!2sbr!4v1731368314849!5m2!1spt-BR!2sbr`,
};
