import React, { useMemo, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "../../../styles/banner.css";
import { imagemURL, banner_defalt } from "../../../services/variables";

export default function ControlledCarousel(props) {
  const [index, setIndex] = useState(0);
  const banner = props.objImg;
  //console.log(banner)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  if (!banner_defalt) {
    const index = banner.findIndex(
      (item) => item.ID === "e03deea9-ea1e-4125-b238-eaf2d615498b"
    );
    if (index !== -1) {
      banner.splice(index, 1);
    }
  }

  return (
    <>
      {banner.length !== 0 && (
        <Carousel activeIndex={index} onSelect={handleSelect} touch={true}>
          {banner.map((item, i) => {
            return (
              <Carousel.Item key={i}>
                <img
                  className="d-block w-100-banner"
                  src={imagemURL + item.IMG}
                  alt="Imagem Banner"
                />
                <Carousel.Caption>
                  <a
                    className="btn-banner-link"
                    variant="primary"
                    href={item.LINK}
                  ></a>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      )}
    </>
  );
}
